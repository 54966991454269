import * as React from "react"
import { Link } from "gatsby"

export default function Update ({ id, image, headline, intro, main = false }) {
  return(
    <div className={ `update ${main ? 'update--main' : ''} ` }>

      <picture className="update__picture">
        <img src={image} alt="" />
      </picture>

      <Link to={`/post/${id}`}>
        <h3 className="update__headline">{ headline }</h3>
      </Link>

      <p className="update__excerpt">{`${intro?.substring(0, 150)} . . .`}</p>

      <hr></hr>
      
    </div>
  )
}